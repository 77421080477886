export class AudioPlayerController {
  constructor() {
    if (AudioPlayerController._instance) {
      return AudioPlayerController._instance
    }
    console.log("=============================== AudioPlayerController INIT ===============================")
    AudioPlayerController._instance = this;

    this.player = undefined
    this.isPlaying = false
    this.currentTime = 0
    this.duration = 0
    this.url = undefined
    this.audioDetails = []
    this.collection = undefined
  }

  initPlayer(collection, url, audioDetails, autoPlay) {
    console.log('initPlayer autoPlay')
    console.log(autoPlay)
    this.collection = collection
    this.parserAudioDetail(audioDetails)
    if (this.player && this.url === url) {
      if (autoPlay) {
        this.play()
      }
      return
    } else if (this.player) {
      this.player.pause()
      this.player = null
    }
    this.player = new Audio(url)
    this.addListening(autoPlay)
  }

  addListening(autoPlay) {
    const _this = this
    _this.player.onloadedmetadata = function() {
      _this.duration = parseInt(_this.player.duration)
      if (autoPlay) {
        _this.player.play()
      }
    };
    _this.player.onplay = function() {
      console.log("Video is playing");
      _this.isPlaying = true
    };
    _this.player.onpause = function() {
      console.log("Video is paused");
      _this.isPlaying = false
    };
    _this.player.onended = function() {
      console.log("Video is onended");
      _this.isPlaying = false
      _this.seekTo(0)
      _this.rate(1)
    };
    _this.player.ontimeupdate = function() {
      _this.currentTime = _this.player.currentTime
    };
  }

  parserAudioDetail(audioDetails) {
    this.audioDetails = []
    audioDetails.sort((a, b) => {
      return a.StartTime > b.StartTime
    })
    audioDetails.forEach(d => {
      const startTime = new Date(d.StartTime)
      d.startSec = startTime.getMinutes() * 60 + startTime.getSeconds()
      const endTime = new Date(d.EndTime)
      d.endSec = endTime.getMinutes() * 60 + endTime.getSeconds()
    })
    this.audioDetails = audioDetails
  }

  play() {
    if (this.player) {
      this.player.play()
    }
  }

  pause() {
    if (this.player) {
      this.player.pause()
      this.isPlaying = false
    }
  }

  seekTo(second) {
    const isPlaying = this.isPlaying
    this.pause()
    this.player.currentTime = second
    if (isPlaying) this.play()
  }

  rate(rate) {
    this.player.playbackRate = rate
  }

  showImage() {
    // const filter = this.audioDetails.filter(d => d.startSec <= this.currentTime && d.endSec >= this.currentTime)[0]
    // if (filter) {
    //   return 'https://homing.ntsec.gov.tw/files/' + filter.ImagePath
    // } else {
    //   return null
    // }
    return this.audioDetails.filter(d => d.startSec <= this.currentTime && d.endSec >= this.currentTime)[0]
  }

  collectionId() {
    return this.collection ? this.collection.CollectionId : null
  }
}
