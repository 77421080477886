<template>
 <div class="fixed-audio-player" :class="{ 'fixed-audio-player-open-detail': isOpenDetail }">
   <div v-if="isOpenDetail === false" class="fixed-audio-info-button" @click="isOpenDetail = true">
     <img src="../../assets/images/icon-audio-play.svg" alt>
   </div>
   <div v-else class="fixed-audio-info-button">
     <img src="../../assets/images/icon-detail-image-scale.svg" alt style="float: left" @click="isOpenDetail = false">
     <img v-if="showCloseButton" src="../../assets/images/icon-menu-close.svg" alt style="float: right" @click="handleStopPlay">
   </div>
   <div class="collection-player-container">
     <div class="collection-player">
       <img
           v-if="$store.getters.isAudioPlaying"
           class="icon-play"
           src="../../assets/images/icon-audio-pause.svg"
           alt="暫停"
           @click="handlePlayAudio()">
       <img
           v-else
           class="icon-play"
           src="../../assets/images/icon-audio-play.svg"
           alt="播放"
           @click="handlePlayAudio()">
       <el-slider
           class="icon-slider"
           v-model="audioModel.currentTime"
           :max="$store.getters.audioPlayerDuration"
           :show-tooltip="showTooltip"
           :format-tooltip="handleSliderFormatTooltip"
           @input="handleSliderInput"
           @change="handleSliderChange"></el-slider>
       <div class="seek-time">
         <!--              <span>{{ audioModel.duration | durationFilter }}</span>-->
         <span>{{ remainTime }}</span>
       </div>
     </div>
     <div class="collection-player-speed-container">
       <el-button type="text" @click="handleChangeSpeed(1.0)">1 x</el-button>
       <el-divider direction="vertical" />
       <el-button type="text" @click="handleChangeSpeed(1.25)">1.25 x</el-button>
       <el-divider direction="vertical" />
       <el-button type="text" @click="handleChangeSpeed(1.5)">1.5 x</el-button>
     </div>
   </div>
   <div class="collection-img">
     <img v-if="audioModel.imgUrl" :src="audioModel.imgUrl" alt>
   </div>
 </div>
</template>

<script>
export default {
  name: "CollectionPlayAudio",
  data() {
    return {
      audioModel: {
        currentTime: 0,
        imgUrl: undefined
      },
      isOpenDetail: false,
      showTooltip: true,
    }
  },
  computed: {
    showCloseButton() {
      return this.$store.getters.forceShowFixedAudioPlayer === false
    },
    isPlaying() {
      return this.$store.getters.isAudioPlaying
    },
    currentTime() {
      if (this.$store.getters.audioPlayerCurrentTime >= 60) {
        this.$store.commit('AddAudioRecord', this.$store.getters.playingCollectionId)
      }
      return this.$store.getters.audioPlayerCurrentTime
    },
    durationTime() {
      return this.$store.getters.audioPlayerDuration
    },
    remainTime() {
      const remainSeconds = this.durationTime - this.currentTime
      const minutes = parseInt(remainSeconds / 60)
      const seconds = parseInt(remainSeconds % 60)
      return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    },
    showImage() {
      return (this.$store.getters.showAudioImage && this.$store.getters.showAudioImage.ImagePath.trim().length > 0) ?
          ('https://homing.ntsec.gov.tw/files/' + this.$store.getters.showAudioImage.ImagePath) : ''
          // 'https://homing.ntsec.gov.tw/files/' + filter.ImagePath
    }
  },
  watch: {
    currentTime(newTime) {
      // console.log(`current: ${oldTime} > ${newTime}`)
      this.audioModel.currentTime = newTime
    },
    showImage(newImg) {
      // console.log(`showImage: ${oldImg} > ${newImg}`)
      this.audioModel.imgUrl = newImg
    }
  },
  methods: {
    handleStopPlay() {
      this.$store.dispatch('StopAudioPlayer')
    },
    handleChangeSpeed(speed) {
      this.$store.dispatch('RateToAudioPlayer', { rate: speed })
    },
    handleSliderFormatTooltip(totalSeconds) {
      const minutes = parseInt(totalSeconds / 60)
      const seconds = parseInt(totalSeconds % 60)
      return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    },
    handleSliderInput() {
      this.showTooltip = true
    },
    handleSliderChange(val) {
      this.$store.dispatch('SeekToAudioPlayer', { currentTime: val })
      this.showTooltip = false
    },
    handlePlayAudio() {
      if (this.$store.getters.isAudioPlaying) {
        this.$store.dispatch('PauseAudioPlayer')
      } else {
        this.$store.dispatch('RePlayAudioPlayer')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.fixed-audio-player {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  background: black;
  border-radius: 30px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 2001;
  .fixed-audio-info-button {
    width: 66.66%;
    height: 66.66%;
    margin: 16.67%;
    text-align: center;
    img {
      width: 80%;
      height: 80%;
      margin: 10%;
    }
  }
  .collection-player-container {
    display: none;
    .collection-player {
      display: flex;
      height: 30px;
      line-height: 30px;
      .icon-play {
        padding: 5px;
        width: 30px;
        height: 30px;
      }
      .icon-slider {
        margin-left: 10px;
        width: calc(100% - 120px);
        .el-slider__bar {
          background-color: #07DB95;
        }
      }
      .seek-time {
        width: 50px;
        text-align: right;
        font-size: 20px;
        line-height: 30px;
        font-weight: lighter;
        color: #C1D6CE;
        margin-left: 5px;
      }
    }
    .collection-player-speed-container {
      display: flex;
      justify-content: center;/*水平置中*/
      align-items: center; /*垂直置中*/
      .el-button {
        color: #07DB95;
        font-family: "Droid Sans", Sans-serif, serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        margin: 0 5px;
      }
      .el-divider--vertical {
        height: 1.3em;
        background-color: #4D565E;
      }
    }
  }
  .collection-img {
    display: none;
    width: 300px;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.fixed-audio-player-open-detail {
  width: 300px;
  height: 340px;
  .fixed-audio-info-button {
    width: 100%;
    height: 40px;
    padding: 10px 0;
    margin: 0;
    img {
      width: 40%;
      height: 32px;
      margin: 0 10px;
      object-position: left;
      object-fit: contain;
    }
    img:nth-child(2) {
      object-position: right;
    }
  }
  .collection-player-container {
    display: block;
  }
  .collection-img {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .fixed-audio-player {
    width: 50px;
    height: 50px;
    right: 20px;
    bottom: 20px;
  }
  .fixed-audio-player-open-detail {
    width: 300px;
    height: 340px;
  }
}
</style>

<style lang="scss">
.icon-slider {
  margin-left: 20px;
  width: calc(100% - 130px);
  .el-slider__runway {
    background-color: #40484F;
    border-radius: 0;
    height: 8px;
    margin: 11px 0;
    .el-slider__bar {
      background-color: #07DB95;
      border-radius: 0;
    }
    .el-slider__button {
      border-color: transparent;
      background-color: transparent;
    }
  }
}
</style>
