import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import {AudioPlayerController} from "@/views/AudioPlayerController";
const apiPath = 'https://homing.ntsec.gov.tw';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    languageKey: localStorage.getItem('languageKey') ?? 'ZH_TW',
    collectionList: [],
    getCollectionDate: undefined,
    userCollectionList: localStorage.getItem('collects') ? JSON.parse(localStorage.getItem('collects')) : [],
    interactionList: [],
    getInteractionDate: undefined,
    loading: false,
    audioRecords: localStorage.getItem('RecordAudio') ? JSON.parse(localStorage.getItem('RecordAudio')) : [],
    interactionRecords: localStorage.getItem('RecordInteraction') ? JSON.parse(localStorage.getItem('RecordInteraction')) : [],
    isFinishAdventure: localStorage.getItem('FinishAdventure') !== null,
    audioPlayer: new AudioPlayerController(),
    forceShowFixedAudioPlayer: false,
    archiveTab: 0,
    redeemed: localStorage.getItem('Redeemed') === 'Y',
    backButtonColor: undefined
  },
  mutations: {
    SetLanguage(state, langKey) {
      state.languageKey = langKey
      localStorage.setItem('languageKey', langKey)
    },
    SetCollection(state, data) {
      state.collectionList = data
      state.getCollectionDate = new Date()
    },
    SetInteraction(state, data) {
      state.interactionList = data
      state.getInteractionDate = new Date()
    },
    SetUserCollected(state, data) {
      state.userCollectionList = data
      localStorage.setItem('collects', JSON.stringify(data))
    },
    LoadingOpen(state) {
      state.loading = true
    },
    LoadingClose(state) {
      state.loading = false
    },
    AddAudioRecord(state, collectionId) {
      if (state.audioRecords.includes(collectionId) === false) {
        state.audioRecords.push(collectionId)
        localStorage.setItem('RecordAudio', JSON.stringify(state.audioRecords))
      }
    },
    RemoveAudioRecord(state, collectionId) {
      if (state.audioRecords.includes(collectionId)) {
        const index = state.audioRecords.indexOf(collectionId)
        state.audioRecords.splice(index, 1)
        localStorage.setItem('RecordAudio', JSON.stringify(state.audioRecords))
      }
    },
    RemoveAllAudioRecord(state) {
      state.audioRecords = []
      localStorage.setItem('RecordAudio', JSON.stringify(state.audioRecords))
    },
    AddInteractionRecord(state, interactionId) {
      const interactionName = interactionId.replace('DesktopExploration', '').replace('MobileExploration', '')
      try {
        if (state.interactionRecords.includes(interactionName) === false) {
          state.interactionRecords.push(interactionName)
          localStorage.setItem('RecordInteraction', JSON.stringify(state.interactionRecords))
        }
      } catch (e) {
        state.interactionRecords = [interactionName]
        localStorage.setItem('RecordInteraction', JSON.stringify(state.interactionRecords))
      }
    },
    RemoveInteractionRecord(state, interactionId) {
      const interactionName = interactionId.replace('DesktopExploration', '').replace('MobileExploration', '')
      if (state.interactionRecords.includes(interactionName)) {
        const index = state.interactionRecords.indexOf(interactionName)
        state.interactionRecords.splice(index, 1)
        localStorage.setItem('RecordInteraction', JSON.stringify(state.interactionName))
      }
    },
    RemoveAllInteractionRecord(state) {
      state.interactionRecords = []
      localStorage.setItem('RecordInteraction', JSON.stringify(state.interactionRecords))
    },
    SetFinishAdventure(state) {
      console.log('FinishAdventure')
      state.isFinishAdventure = true
      localStorage.setItem('FinishAdventure', 1)
    },
    RemoveFinishAdventure(state) {
      state.isFinishAdventure = false
      localStorage.removeItem('FinishAdventure')
    },
    ForceShowFixedAudioPlayer(state, isForce) {
      state.forceShowFixedAudioPlayer = isForce
    },
    SetRedeemed(state) {
      state.redeemed = true
      localStorage.setItem('Redeemed', 'Y')
    },
    SetBackButtonColor(state, color) {
      state.backButtonColor = color
    }
  },
  actions: {
    SetupLanguage(context, languageKey) {
      context.commit('SetLanguage', languageKey)
    },
    GetCollectionList(context) {
      return new Promise(resolve => {
        if (context.state.collectionList.length === 0 || context.state.getCollectionDate === undefined || (new Date().getTime() - context.state.getCollectionDate.getTime() > 60 * 60 * 1000)) {
          axios.post(`${apiPath}/api/Collection/List`).then(res => {
            const collections = res.data.Result
            collections.forEach(x => {
              x.IsCollect = context.state.userCollectionList.includes(x.CollectionId)
            })
            context.commit('SetCollection', collections)
            resolve({
              success: true,
              collections
            })
          }).catch(e => {
            resolve({
              success: false,
              message: e
            })
          })
        } else {
          const collections = context.state.collectionList
          resolve({
            success: true,
            collections
          })
        }
      })
    },
    GetInteractionList(context) {
      return new Promise(resolve => {
        if (context.state.interactionList.length === 0 || context.state.getInteractionDate === undefined || (new Date().getTime() - context.state.getInteractionDate.getTime() > 60 * 60 * 1000)) {
          axios.post(`${apiPath}/api/Interaction/List`).then(res => {
            const interactions = res.data.Result
            context.commit('SetInteraction', interactions)
            resolve({
              success: true,
              interactions
            })
          }).catch(e => {
            resolve({
              success: false,
              message: e
            })
          })
        } else {
          const interactions = context.state.interactionList
          resolve({
            success: true,
            interactions
          })
        }
      })
    },
    ClearRecords(context) {
      context.commit('RemoveAllAudioRecord')
      context.commit('RemoveAllInteractionRecord')
      context.commit('RemoveFinishAdventure')
    },
    PlayAudioPlayer(context, { collectionData, collectionId, lang, autoPlay }) {
      return new Promise(resolve => {
        const collection = collectionData ?? context.state.collectionList.filter(c => c.CollectionId === collectionId)[0]
        if (!collection) {
          return resolve({
            success: false,
            message: '查無導覽'
          })
        }
        const langCollection = collection.CollectionDetails.filter(d => d.LanguageKey === lang)[0]
        if (!langCollection) {
          return resolve({
            success: false,
            message: '該導覽無對應的語系明細'
          })
        }
        if (!langCollection.AudioFilePath || langCollection.AudioFilePath.trim().length === 0) {
          return resolve({
            success: false,
            message: '該導覽語系明細沒有語音檔案'
          })
        }
        context.state.audioPlayer.initPlayer(collection, `https://homing.ntsec.gov.tw/files/${langCollection.AudioFilePath}`, langCollection.CollectionDetailAudios, autoPlay)
        console.log('PlayAudioPlayer success')
        return resolve({
          success: true
        })
      })
    },
    PauseAudioPlayer(context) {
      if (context.state.audioPlayer) {
        context.state.audioPlayer.pause()
      }
    },
    StopAudioPlayer(context) {
      if (context.state.audioPlayer) {
        context.state.audioPlayer.pause()
        context.state.audioPlayer.seekTo(0)
      }
    },
    SeekToAudioPlayer(context, { currentTime }){
      context.state.audioPlayer.seekTo(currentTime)
    },
    RateToAudioPlayer(context, { rate }){
      context.state.audioPlayer.rate(rate)
    },
    RePlayAudioPlayer(context) {
      if (context.state.audioPlayer) context.state.audioPlayer.play()
    },
    UploadQuestionnaire(context, data) {
      return new Promise(resolve => {
        axios.post(`${apiPath}/api/Questionnaire/UploadRecord`, {Records: data}).then(res => {
          context.commit('SetFinishAdventure')
          resolve({
            success: true,
            data: res.data
          })
        }).catch(e => {
          resolve({
            success: false,
            message: e
          })
        })
      })
    },
    SetupArchiveTab(context, tab) {
      context.state.archiveTab = tab
    },
    SendClickEvent(context, data) {
      return new Promise(resolve => {
        if (data.controller && data.id && data.detailId && data.languageKey) {
          axios.post(`${apiPath}/api/${data.controller}/Clicked`, data).then(res => {
            context.commit('SetFinishAdventure')
            resolve({
              success: true,
              data: res.data
            })
          }).catch(e => {
            resolve({
              success: false,
              message: e
            })
          })
        } else {
          resolve({
            success: false
          })
        }
      })
    },
  },
  modules: {
  },
  getters: {
    languageKey(state) {
      return state.languageKey
    },
    // listenAudioCount() {
    //   return 100
    // },
    // playInteractionCount() {
    //   return 5
    // },
    // didFinishAdventure() {
    //   return true
    // },
    listenAudioCount(state) {
      return state.audioRecords.length
    },
    playInteractionCount(state) {
      return state.interactionRecords.length
      // return 10
    },
    didFinishAdventure(state) {
      return state.isFinishAdventure
    },
    collectionsCount(state) {
      return state.collectionList.length
    },
    isAudioPlaying(state) {
      return state.audioPlayer.isPlaying
    },
    audioPlayerDuration(state) {
      return state.audioPlayer.duration
    },
    audioPlayerCurrentTime(state) {
      return state.audioPlayer.currentTime
    },
    showFixedAudioPlayer(state) {
      return state.audioPlayer.player && state.audioPlayer.currentTime > 0
    },
    forceShowFixedAudioPlayer(state) {
      return state.forceShowFixedAudioPlayer
    },
    showAudioImage(state) {
      return state.audioPlayer.showImage()
    },
    playingCollectionId(state) {
      return state.audioPlayer.collectionId()
    },
    getArchiveTab(state) {
      return state.archiveTab
    },
    getRedeemed(state) {
      return state.redeemed
    },
    isMobile() {
      const agent = navigator.userAgent
      return agent.match(/(iPhone|iPod)/i) || agent.toLowerCase().includes("android")
    },
    getBackButtonColor(state) {
      return state.backButtonColor
    }
  }
})
