<template>
  <div id="app2">
    <router-view/>
    <div v-if="showMenu && isMenuOpened" class="icon-menu-container-open">
      <el-button type="text" @click="handleGoPage('Welcome')">{{ getTitleByLanguage('回首頁') }}</el-button>
      <el-button type="text" @click="handleGoPage('MainMenu')">{{ getTitleByLanguage('主選單') }}</el-button>
      <el-button type="text" @click="isSearchShow = true; isMenuOpened = false">{{ getTitleByLanguage('找編號') }}</el-button>
      <img src="../assets/images/icon-menu-close.svg" alt @click="handleCloseMenu">
    </div>
    <div v-if="showMenu && isMenuOpened === false" :class="getIconMenuClass">
      <img src="../assets/images/icon-menu.svg" alt @click="handleOpenMenu">
    </div>
    <div v-if="showBackButton" :class="getBackButtonClass" @click="handleGoBack">
      <img v-if="showGreenBack" src="../assets/images/icon-arrow-left-green.svg" alt>
      <img v-else src="../assets/images/icon-arrow-left-black.svg" alt>
    </div>
    <collection-play-audio v-show="showFixedAudioPlayer"></collection-play-audio>
    <div v-if="isSearchShow" class="popup-fixed" style="overflow: hidden; z-index: 3001">
      <way-home-search-collection @close="isSearchShow = false;" @searched="goCollection"></way-home-search-collection>
    </div>
  </div>
</template>
<script>
import CollectionPlayAudio from "@/views/desktop/CollectionPlayAudio";
import WayHomeSearchCollection from "@/views/desktop/WayHome/WayHomeSearchCollection";
export default {
  components: {WayHomeSearchCollection, CollectionPlayAudio},
  data() {
    return {
      loadingComponent: undefined,
      isMenuOpened: false,
      lastTap: 0,
      timeout: undefined,
      isSearchShow: false,
    }
  },
  watch: {
    '$store.state.loading': function (value) {
      if (value && this.loadingComponent === undefined) {
        this.loadingComponent = this.$loading({
          lock: true,
          text: '載入中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else if (value === false && this.loadingComponent) {
        this.loadingComponent.close();
        this.loadingComponent = undefined
      }
    },
    $route (){
      this.isMenuOpened = false
    }
  },
  computed: {
    showMenu() {
      return this.$route.name.includes('Welcome') === false && this.$route.name.includes('MainMenu') === false
    },
    showFixedAudioPlayer() {
      if (this.$store.getters.forceShowFixedAudioPlayer) {
        return true
      } else {
        return this.$store.getters.showFixedAudioPlayer && this.$route.name.includes('WayHome2') === false;
      }
    },
    showBackButton() {
      return this.$route.name.includes('Welcome') === false
    },
    showGreenBack() {
      console.log('showGreenBack() this.$store.getters.getBackButtonColor')
      console.log(this.$store.getters.getBackButtonColor)
      if (this.$store.getters.getBackButtonColor) {
        return this.$store.getters.getBackButtonColor === 'green'
      }

      if (this.$route.name.includes('WayHome1')) {
        return false
      }
      else if ((this.$route.name.includes('Exploration0') || this.$route.name.includes('WayHome0')) && this.$store.getters.isMobile) {
        return false
      }
      return true
    },
    getBackButtonClass() {
      if (this.$store.getters.isMobile) {
        return 'mobile-left-top-back-button'
      } else {
        return 'desktop-left-top-back-button'
      }
    },
    getIconMenuClass() {
      if (this.$store.getters.isMobile) {
        return 'mobile-icon-menu-container'
      } else {
        return 'icon-menu-container'
      }
    }
  },
  filters: {
  },
  created() {
  },
  mounted() {
    // var lastTouchEnd = (new Date()).getTime();
    // document.addEventListener('touchend', function (event) {
    //   var now = (new Date()).getTime();
    //   console.log(now - lastTouchEnd)
    //   if (now - lastTouchEnd <= 300) {
    //     event.preventDefault()
    //   }
    //   lastTouchEnd = now;
    // }, false);
  },
  methods: {
    getTitleByLanguage(title) {
      switch (title) {
        case '回首頁':
          return this.$store.getters.languageKey === 'ZH_TW' ? '回首頁' : 'Home'
        case '主選單':
          return this.$store.getters.languageKey === 'ZH_TW' ? '主選單' : 'Menu'
        case '找編號':
          return this.$store.getters.languageKey === 'ZH_TW' ? '找編號' : 'Search'
      }
    },
    handleOpenMenu() {
      this.isMenuOpened = true
    },
    handleCloseMenu() {
      this.isMenuOpened = false
    },
    handleGoPage(pageName) {
      this.handleCloseMenu()
      const agent = navigator.userAgent
      if (agent.match(/(iPhone|iPod)/i) || agent.toLowerCase().includes("android")) {
        this.$router.push({
          name: `Mobile${pageName}`
        })
      } else {
        this.$router.push({
          name: `Desktop${pageName}`
        })
      }
    },
    handleGoBack() {
      this.$router.back()
    },
    goCollection(collection) {
      this.isSearchShow = false
      this.$router.push({
        name: this.$store.getters.isMobile ? 'MobileWayHome2' : 'DesktopWayHome2',
        params: {
          id: collection.CollectionId
        }
      })
    },
  }
}
</script>
<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
  //user-select: none;
  //position: relative;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-loading-spinner {
  .el-icon-loading {
    width: 50px;
    height: 50px;
    background-image: url("../assets/images/icon-loading.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .el-icon-loading:before {
    display: none;
  }
  .el-loading-text {
    color: #06DB94 !important;
    margin-top: 10px !important;
  }
}

.icon-menu-container {
  position: absolute;
  left: 50px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  z-index: 2001;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.icon-menu-container-open {
  position: absolute;
  left: 50px;
  bottom: 50px;
  width: 60px;
  height: 180px;
  background-color: #080A0D;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  z-index: 2001;
  .el-button {
    height: 40px;
    margin-left: 0 !important;
    color: #07DB95;
    font-size: 0.8em;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 20px auto;
  }
}

.mobile-icon-menu-container {
  position: fixed;
  left: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
}
.mobile-icon-menu-container-open {
  left: 20px;
  bottom: 20px;
  width: 50px;
  height: 140px;
}
</style>



