import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index')
  },
  {
    path: '/desktop',
    component: Layout,
    children: [
      {
        path: 'welcome',
        name: 'DesktopWelcome',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Welcome.vue')
      },
      {
        path: 'menu',
        name: 'DesktopMainMenu',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/MainMenu.vue')
      },
      {
        path: 'way-home-0',
        name: 'DesktopWayHome0',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/WayHome/WayHome0.vue')
      },
      {
        path: 'way-home-1',
        name: 'DesktopWayHome1',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/WayHome/WayHome1.vue')
      },
      {
        path: 'way-home-2/:id',
        name: 'DesktopWayHome2',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/WayHome/WayHome2.vue')
      },
      {
        path: 'way-home-map',
        name: 'DesktopWayHomeMap',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/WayHome/WayHomeMap.vue')
      },
      {
        path: 'way-home-detail/:id',
        name: 'DesktopWayHomeDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/WayHome/WayHomeDetail.vue')
      },
      {
        path: 'exploration-0',
        name: 'DesktopExploration0',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/Exploration0')
      },
      {
        path: 'exploration-1',
        name: 'DesktopExploration1',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/Exploration1')
      },
      {
        path: 'exploration-3D-list',
        name: 'DesktopExploration3DList',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/Exploration3DList')
      },
      {
        path: 'exploration-3D',
        name: 'DesktopExploration3D',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/Exploration3D')
      },
      {
        path: 'exploration-AR-list',
        name: 'DesktopExplorationARList',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/ExplorationARList')
      },
      {
        path: 'exploration-AR',
        name: 'DesktopExplorationAR',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/ExplorationAR')
      },
      {
        path: 'exploration-AR-bird',
        name: 'DesktopExplorationARBird',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/AR-Bird/ArBird')
      },
      {
        path: 'exploration-AR-snake',
        name: 'DesktopExplorationARSNAKE',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/AR-Snake/ArSnake')
      },
      {
        path: 'exploration-AR-cuttle',
        name: 'DesktopExplorationARCUTTLEFISH',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/Exploration/AR-Cuttle/ArCuttle')
      },
      {
        path: 'adventure-archive',
        name: 'DesktopAdventureArchive',
        component: () => import(/* webpackChunkName: "about" */ '../views/desktop/AdventureArchive/AdventureArchiveIndex')
      }
    ]
  },
  {
    path: '/mobile',
    component: Layout,
    children: [
      {
        path: 'welcome',
        name: 'MobileWelcome',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Welcome.vue')
      },
      {
        path: 'menu',
        name: 'MobileMainMenu',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/MainMenu.vue')
      },
      {
        path: 'way-home-0',
        name: 'MobileWayHome0',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/WayHome/WayHome0.vue')
      },
      {
        path: 'way-home-1',
        name: 'MobileWayHome1',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/WayHome/WayHome1.vue')
      },
      {
        path: 'way-home-2/:id',
        name: 'MobileWayHome2',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/WayHome/WayHome2.vue')
      },
      {
        path: 'way-home-map',
        name: 'MobileWayHomeMap',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/WayHome/WayHomeMap.vue')
      },
      {
        path: 'way-home-detail/:id',
        name: 'MobileWayHomeDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/WayHome/WayHomeDetail.vue')
      },
      {
        path: 'exploration-0',
        name: 'MobileExploration0',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/Exploration0')
      },
      {
        path: 'exploration-1',
        name: 'MobileExploration1',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/Exploration1')
      },
      {
        path: 'exploration-3D-list',
        name: 'MobileExploration3DList',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/Exploration3DList')
      },
      {
        path: 'exploration-3D',
        name: 'MobileExploration3D',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/Exploration3D')
      },
      {
        path: 'exploration-AR-list',
        name: 'MobileExplorationARList',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/ExplorationARList')
      },
      {
        path: 'exploration-AR-bird',
        name: 'MobileExplorationARBird',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/AR-Bird/ArBird')
      },
      {
        path: 'exploration-AR-snake',
        name: 'MobileExplorationARSNAKE',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/AR-Snake/ArSnake')
      },
      {
        path: 'exploration-AR-cuttle',
        name: 'MobileExplorationARCUTTLEFISH',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/Exploration/AR-Cuttle/ArCuttle')
      },
      {
        path: 'adventure-archive',
        name: 'MobileAdventureArchive',
        component: () => import(/* webpackChunkName: "about" */ '../views/mobile/AdventureArchive/AdventureArchiveIndex')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
})

export default router
