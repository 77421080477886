import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import style from './style/index.scss'

Vue.config.productionTip = false

import 'element-ui/lib/theme-chalk/index.css'
import Element from 'element-ui'
import ZhTW from 'element-ui/lib/locale/lang/zh-TW'
import './registerServiceWorker'
Vue.use(Element, {
  size: 'mini', // set element-ui default size
  // locale: enLang // 如果使用中文，无需设置，请删除
  locale: ZhTW,
})

Vue.use(style)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
